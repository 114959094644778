<template>
    <div class="category-form">

        <div class="form-content-box">
        
            <div class="form-container">
                <div class="form-body">

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>父级分类</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-cascader 
                                placeholder="请选择父级分类，若未选择则作为顶级分类"
                                :options="categories"
                                :value="currentCat.label"
                                @change="chooseCategory"
                            ></n-cascader>
                            <div class="msg-box success" v-if="formData.parent_id === '' || formData.parent_id === null">作为顶级分类</div>
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>类型</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-select 
                                :value="currentType.label" 
                                :options="typeOptions"
                                placeholder="请选择类型" 
                                @choose="chooseType"
                            />
                            <div class="msg-box danger" v-if="formData.type === ''">必须选择栏目类型</div>
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>分类标题</span>
                                <em>*</em>
                            </div>
                        </div>
                        <div class="item">
                            <n-input 
                                v-model:value="formData.title" 
                                placeholder="请输入分类标题" 
                                :msg="formData.title === '' ? '标题不能为空' : ''"
                            />
                        </div>
                    </div>

                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>关键字</span>
                            </div>
                        </div>
                        <div class="item keywords">
                            <n-tag 
                                class="tag"
                                theme="fill" type="primary"
                                v-for="(item, index) in formData.keywords" :key="index"
                                @close="removeKeyword(index)"
                            >
                                {{ item }}
                            </n-tag>
                            <n-input
                                class="keyword-input"
                                v-model:value="keyword"
                                @enter="addKeyword"
                                placeholder="输入关键字"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="label">
                            <div class="label-container">
                                <span>描述</span>
                            </div>
                        </div>
                        <div class="item">
                            <n-textarea 
                                v-model:value="formData.description"
                                :maxlength="140"
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-container form-editor" v-if="currentType.value === 'page'">
                <n-editor class="form-content" v-model:data="formData.content" />
            </div>

        </div>

        <div class="form-slider">
            <div class="item">
                <h5>作者</h5>
                <div class="info author">
                    <n-avatar :size="40" />
                    <div class="name-box">
                        <span class="name">{{ $store.state.userInfo.name }}</span>
                        <span class="phone">{{ $store.state.userInfo.phone }}</span>
                    </div>
                </div>
            </div>
            <div class="item">
                <h5>发表状态</h5>
                <div class="info">
                    <n-select 
                        :zIndex="9999"
                        v-model:value="formData.status.label" 
                        :options="statuses" 
                        placeholder="请选择状态"
                        @choose="checkStatus"
                    />
                </div>
            </div>
            <div class="item">
                <h5>访问权限</h5>
                <div class="info">
                    <n-select 
                        :zIndex="9999"
                        v-model:value="formData.permissions.label" 
                        placeholder="请选择文章访问权限"
                    >
                        <template v-for="(item, index) in permissionses" :key="index">
                            <n-select-option 
                                :label="item.label" :index="index"
                                :value="formData.permissions"
                                :checked="formData.permissions.label === item.label"
                                @choose="checkPermission"
                            />
                        </template>
                    </n-select>
                </div>
            </div>
            <div class="item">
                <h5>发表时间</h5>
                <div class="info published">
                    <n-date-picker 
                        class="one" 
                        :date="formData.published_at.date" 
                        @change="dateChange" 
                    />
                    <n-time-picker 
                        class="one" 
                        position="bottom right" 
                        :time="formData.published_at.time"
                        @change="timeChange" 
                    />
                </div>
            </div>
            <div class="item">
                <h5>缩略图</h5>
                <div class="info thumb">
                    <n-upload v-model:src="formData.thumb" width="auto" :height="150" />
                </div>
            </div>
        </div>

    </div>

    <div class="bottom-tools-box">
        <n-button class="tool-btn" size="large" @click="reset">重置</n-button>
        <n-button 
            class="tool-btn" 
            size="large" 
            type="primary" 
            :loading="btnLoading"
            :disabled="btnDisabled"
            @click="submit"
        >确定</n-button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NEditor from '@/components/layout/Editor.vue';

@Options({
    components: {
        NEditor
    },
    props: {
        data: {
            type: Object
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            categories: [],
            currentCat: {},
            formData: {
                parent_id: '',
                type: '',
                author: '',
                title: '',
                keywords: [],
                description: '',
                content: '',
                status: {
                    label: '发布',
                    value: 'published'
                },
                permissions: {
                    label: '公开',
                    value: 'public'
                },
                published_at: {
                    date: {},
                    time: {}
                }
            },
            keyword: '',
            currentType: {},
            typeOptions: [
                {
                    label: '单页面',
                    value: 'page'
                },{
                    label: '产品列表',
                    value: 'equipment'
                },{
                    label: '文章列表',
                    value: 'article'
                }
            ],
            statuses: [
                {
                    label: '发布',
                    value: 'published'
                },{
                    label: '草稿',
                    value: 'draft'
                },{
                    label: '回收站',
                    value: 'deleted'
                }
            ],
            permissionses: [
                {
                    label: '私有',
                    value: 'private'
                },{
                    label: '公开',
                    value: 'public'
                }
            ],
            btnDisabled: true
        }
    },
    watch:{
        'data':{
            handler(){
                this.formData = this.data;
                this.currentType = this.data.type;
                this.formData.author = this.$store.state.userInfo.name;
                if(this.data.parent_id){
                    this.getCategory(Number(this.data.parent_id));
                }
            },
            deep: true
        },
        'formData': {
            handler(){
                if(this.formData.type.value === 'page'){
                    if(this.formData.content === '') this.btnDisabled = true;
                    else this.btnDisabled = false;
                } else {
                    if(this.formData.title !== '' && this.formData.type !== ''){
                        this.btnDisabled = false;
                    } else this.btnDisabled = true;
                }
            },
            deep: true
        }
    },
    emits: ['submit'],
    mounted(){
        this.getCategories();
        this.$api.getUserInfo().then((res: any) => {
            this.$store.commit('changeUserInfo', res.data);
            this.formData.author = res.data.name;
        });
    },
    methods: {
        getCategories(){
            this.$api.xrCategories().then((res: any) => {
                this.categories = this.formatCategory(res.data);
            })
        },
        getCategory(id: number){
            this.$api.xrCategoryShow(id).then((res: any) => {
                this.currentCat = {
                    label: res.data.title,
                    id: id
                };
            })
        },
        formatCategory(categories: any = []){
            const arr: any = [];
            categories.forEach((item: any, index: number) => {
                const obj = {
                    id: item.id,
                    label: item.title,
                    children: []
                }
                if(item.children) obj.children = this.formatCategory(item.children);
                arr.push(obj);
            });
            return arr;
        },
        findCat(id: number, array: any = []){
            array.forEach((item: any) => {
                if(item.id === id) this.currentCat = item;
                if(item.children.length > 0) this.findCat(id, item.children);
            });
        },
        chooseCategory(e: any){
            if(e.grandsun !== '') 
                this.currentCat = this.categories[e.parent].children[e.child].children[e.grandsun];
            else if(e.child !== '') {
                this.currentCat = this.categories[e.parent].children[e.child];
            } else this.currentCat = this.categories[e.parent];

            this.formData.parent_id = this.currentCat.id;
        },
        chooseType(e: any){
            this.currentType = e;
            this.formData.type = e;
        },
        addKeyword(){
            this.formData.keywords.push(this.keyword);
            this.keyword = ''
        },
        removeKeyword(index: number){
            this.formData.keywords.splice(index, 1);
        },
        checkStatus(e: any){
            this.formData.status = e;
        },
        checkPermission(index: number){
            this.formData.permissions = this.permissionses[index]
        },
        dateChange(e: any){
            this.formData.published_at.date = e;
        },
        timeChange(e: any){
            this.formData.published_at.time = e;
        },
        reset(){
            location.reload();
        },
        submit(){
            this.formData.author = this.$store.state.userInfo.name;
            this.$emit('submit', this.formData);
        }
    }
})

export default class CategoryFrom extends Vue {}
</script>

<style lang="scss" scoped>
@import 'form-style';
.category-form{
    @extend .flex-row-between;
    width: 100%;
    .form-content-box{
        width: 74%;
        border-radius: $radius;
        background-color: $white;
        box-shadow: $shadow-default;
        .form-container{
            margin: 0;
            box-shadow: none;
            .msg-box{
                padding: 10px 10px 5px;
                margin-top: -5px;
                border-radius: 0 0 $radius-big $radius-big;
                &.success{
                    background-color: $success-light;
                    color: $success;
                }
                &.danger{
                    background-color: $danger-light;
                    color: $danger;
                }
            }
        }
    }
}
</style>