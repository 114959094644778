
import { Options, Vue } from 'vue-class-component';
import NEditor from '@/components/layout/Editor.vue';

@Options({
    components: {
        NEditor
    },
    props: {
        data: {
            type: Object
        },
        btnLoading: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            categories: [],
            currentCat: {},
            formData: {
                parent_id: '',
                type: '',
                author: '',
                title: '',
                keywords: [],
                description: '',
                content: '',
                status: {
                    label: '发布',
                    value: 'published'
                },
                permissions: {
                    label: '公开',
                    value: 'public'
                },
                published_at: {
                    date: {},
                    time: {}
                }
            },
            keyword: '',
            currentType: {},
            typeOptions: [
                {
                    label: '单页面',
                    value: 'page'
                },{
                    label: '产品列表',
                    value: 'equipment'
                },{
                    label: '文章列表',
                    value: 'article'
                }
            ],
            statuses: [
                {
                    label: '发布',
                    value: 'published'
                },{
                    label: '草稿',
                    value: 'draft'
                },{
                    label: '回收站',
                    value: 'deleted'
                }
            ],
            permissionses: [
                {
                    label: '私有',
                    value: 'private'
                },{
                    label: '公开',
                    value: 'public'
                }
            ],
            btnDisabled: true
        }
    },
    watch:{
        'data':{
            handler(){
                this.formData = this.data;
                this.currentType = this.data.type;
                this.formData.author = this.$store.state.userInfo.name;
                if(this.data.parent_id){
                    this.getCategory(Number(this.data.parent_id));
                }
            },
            deep: true
        },
        'formData': {
            handler(){
                if(this.formData.type.value === 'page'){
                    if(this.formData.content === '') this.btnDisabled = true;
                    else this.btnDisabled = false;
                } else {
                    if(this.formData.title !== '' && this.formData.type !== ''){
                        this.btnDisabled = false;
                    } else this.btnDisabled = true;
                }
            },
            deep: true
        }
    },
    emits: ['submit'],
    mounted(){
        this.getCategories();
        this.$api.getUserInfo().then((res: any) => {
            this.$store.commit('changeUserInfo', res.data);
            this.formData.author = res.data.name;
        });
    },
    methods: {
        getCategories(){
            this.$api.xrCategories().then((res: any) => {
                this.categories = this.formatCategory(res.data);
            })
        },
        getCategory(id: number){
            this.$api.xrCategoryShow(id).then((res: any) => {
                this.currentCat = {
                    label: res.data.title,
                    id: id
                };
            })
        },
        formatCategory(categories: any = []){
            const arr: any = [];
            categories.forEach((item: any, index: number) => {
                const obj = {
                    id: item.id,
                    label: item.title,
                    children: []
                }
                if(item.children) obj.children = this.formatCategory(item.children);
                arr.push(obj);
            });
            return arr;
        },
        findCat(id: number, array: any = []){
            array.forEach((item: any) => {
                if(item.id === id) this.currentCat = item;
                if(item.children.length > 0) this.findCat(id, item.children);
            });
        },
        chooseCategory(e: any){
            if(e.grandsun !== '') 
                this.currentCat = this.categories[e.parent].children[e.child].children[e.grandsun];
            else if(e.child !== '') {
                this.currentCat = this.categories[e.parent].children[e.child];
            } else this.currentCat = this.categories[e.parent];

            this.formData.parent_id = this.currentCat.id;
        },
        chooseType(e: any){
            this.currentType = e;
            this.formData.type = e;
        },
        addKeyword(){
            this.formData.keywords.push(this.keyword);
            this.keyword = ''
        },
        removeKeyword(index: number){
            this.formData.keywords.splice(index, 1);
        },
        checkStatus(e: any){
            this.formData.status = e;
        },
        checkPermission(index: number){
            this.formData.permissions = this.permissionses[index]
        },
        dateChange(e: any){
            this.formData.published_at.date = e;
        },
        timeChange(e: any){
            this.formData.published_at.time = e;
        },
        reset(){
            location.reload();
        },
        submit(){
            this.formData.author = this.$store.state.userInfo.name;
            this.$emit('submit', this.formData);
        }
    }
})

export default class CategoryFrom extends Vue {}
